import router from './router'
import { useAppStoreWithOut } from '@/store/modules/app'
import type { RouteRecordRaw } from 'vue-router'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePageLoading } from '@/hooks/web/usePageLoading'
import { NO_REDIRECT_WHITE_LIST } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { getQueryParams, setCssVar } from '@/utils'
import { getUserInfoApi } from './api/login'
import { useLocale } from '@/hooks/web/useLocale'

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

router.beforeEach(async (to, from, next) => {
  start()
  loadStart()
  const permissionStore = usePermissionStoreWithOut()
  const appStore = useAppStoreWithOut()
  const userStore = useUserStoreWithOut()
  if (to.query.lang !== undefined) {
    const { changeLocale } = useLocale()
    let lang = ''
    if (to.query.lang === 'zh-cn' || to.query.lang === 'zh-CN') {
      lang = 'zh-CN'
    } else {
      // 目前只支持中文和英文
      lang = 'en'
    }
    await changeLocale(lang as LocaleType)
  }
  if (userStore.getUserInfo) {
    // 针对历史客户端，重新刷新 token，避免后端 jwt 过期
    const routeQuerys = to.query
    const currentDate = new Date()
    const startDate = new Date('2024-12-26')
    const endDate = new Date('2025-01-05')
    if (currentDate >= startDate && currentDate <= endDate && routeQuerys.token) {
      const userInfo = await getUserInfoApi({
        token: routeQuerys.token,
        uuid: routeQuerys.uuid,
        employee_number: routeQuerys.employee_number,
        employee_email: routeQuerys.email,
        from: routeQuerys.from
      })
      console.log('userInfo:', userInfo)
      if (userInfo && userInfo.code == 0) {
        console.log(userInfo.data.token)
        userStore.setUserInfo(userInfo.data)
      }
    }

    userStore.setToken(userStore.getUserInfo.token)
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // 解决有些页面全屏显示问题
      if (to.meta && to.meta.isFull === true) {
        appStore.setFullScreen(true)
        setCssVar('--app-content-padding', 0)
        setCssVar('--app-content-bg-color', '#FFFFFF')
      } else {
        appStore.setFullScreen(false)
        setCssVar('--app-content-bg-color', '#f5f7f9')
        setCssVar('--app-content-padding', '20px')
      }
      if (permissionStore.getIsAddRouters) {
        next()
        return
      }

      // 开发者可根据实际情况进行修改
      const roleRouters = userStore.getRoleRouters || []

      // 是否使用动态路由
      if (appStore.getDynamicRouter) {
        appStore.serverDynamicRouter
          ? await permissionStore.generateRoutes('server', roleRouters as AppCustomRouteRecordRaw[])
          : await permissionStore.generateRoutes('frontEnd', roleRouters as string[])
      } else {
        await permissionStore.generateRoutes('static')
      }

      permissionStore.getAddRouters.forEach((route) => {
        router.addRoute(route as unknown as RouteRecordRaw) // 动态添加可访问路由表
      })
      const redirectPath = from.query.redirect || to.path
      const redirect = decodeURIComponent(redirectPath as string)
      const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
      permissionStore.setIsAddRouters(true)
      next(nextData)
    }
  } else {
    if (NO_REDIRECT_WHITE_LIST.indexOf(to.path) !== -1) {
      next()
    } else {
      const params = getQueryParams()
      const routeQuerys = to.query
      console.log('params:', params)
      console.log('routeQuerys:', routeQuerys)
      if (params.token) {
        const userInfo = await getUserInfoApi({ oauth_token: params.oauth_token })
        console.log('userInfo:', userInfo)
        if (userInfo && userInfo.code == 0) {
          userStore.setUserInfo(userInfo.data)
          userStore.setToken(userInfo.data.token)
          window.location.href = '/'
        }
      } else if (routeQuerys.token) {
        // 兼容 oa query 数据
        const userInfo = await getUserInfoApi({
          token: routeQuerys.token,
          uuid: routeQuerys.uuid,
          employee_number: routeQuerys.employee_number,
          employee_email: routeQuerys.email,
          from: routeQuerys.from
        })
        console.log('userInfo:', userInfo)
        if (userInfo && userInfo.code == 0) {
          userStore.setUserInfo(userInfo.data)
          userStore.setToken(userInfo.data.token)
          // 将查询参数转换为字符串
          const queryString = Object.entries(routeQuerys)
            .map(
              ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
            )
            .join('&')
          next(`${to.path}?${queryString}`)
        }
      }
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
    }
  }
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})
