import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/welcome/index',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/welcome',
    component: Layout,
    name: 'Welcome',
    redirect: '/welcome/index',
    meta: {},
    children: [
      {
        path: 'index',
        name: 'WelcomeContent',
        component: () => import('@/views/Dashboard/Welcome.vue'),
        meta: {
          title: t('router.welcome'),
          icon: 'ep:guide'
        }
      }
    ]
  },
  {
    path: '/tag',
    component: Layout,
    name: 'Tag',
    redirect: '/tag/index',
    meta: {},
    children: [
      {
        path: 'index',
        name: 'TagContent',
        component: () => import('@/views/Tag/Index.vue'),
        meta: {
          title: t('router.tag'),
          icon: 'mdi:file-document-box-search-outline'
        }
      }
    ]
  },
  {
    path: '/kv',
    component: Layout,
    name: 'KeyValue',
    redirect: '/kv/index',
    meta: {},
    children: [
      {
        path: 'index',
        name: 'KVContent',
        component: () => import('@/views/KV/Index.vue'),
        meta: {
          title: 'KeyValue',
          icon: 'mdi:file-document-box-search-outline'
        }
      },
      {
        path: 'add',
        name: 'KVAddContent',
        component: () => import('@/views/KV/Add.vue'),
        meta: {
          title: 'KeyValueAdd',
          noTagsView: true,
          noCache: true,
          hidden: true,
          canTo: true,
          activeMenu: '/kv/add'
        }
      },
      {
        path: 'detail',
        name: 'KVDetailContent',
        component: () => import('@/views/KV/Detail.vue'),
        meta: {
          title: 'KeyValueDetail',
          noTagsView: true,
          noCache: true,
          hidden: true,
          canTo: true,
          activeMenu: '/kv/detail'
        }
      },
      {
        path: 'edit',
        name: 'KVEditContent',
        component: () => import('@/views/KV/Edit.vue'),
        meta: {
          title: 'KeyValueEdit',
          noTagsView: true,
          noCache: true,
          hidden: true,
          canTo: true,
          activeMenu: '/kv/edit'
        }
      }
    ]
  },
  {
    path: '/form',
    component: Layout,
    name: 'Form',
    redirect: '/form/index',
    meta: {
      title: t('router.form'),
      icon: 'mdi:file-document-box-search-outline'
    },
    children: [
      {
        path: 'index',
        name: 'FormContent',
        component: () => import('@/views/Form/Index.vue'),
        meta: {
          title: t('router.form'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true
        }
      },
      {
        path: 'detail',
        name: 'FormDetailContent',
        component: () => import('@/views/Form/Detail.vue'),
        meta: {
          title: t('router.detail'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true
        }
      },
      {
        path: 'wp',
        name: 'wpFormContent',
        component: () => import('@/views/Form/wp.vue'),
        meta: {
          title: t('router.wp'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true,
          noCache: true
        }
      },
      {
        path: 'wp-detail',
        name: 'wpDetailContent',
        component: () => import('@/views/Form/detail/wp.vue'),
        meta: {
          title: t('router.wpDetail'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true,
          noCache: true
        }
      },
      {
        path: 'budget',
        name: 'BudgetContent',
        component: () => import('@/views/Form/budget.vue'),
        meta: {
          title: t('formPage.budget'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true,
          noCache: true
        }
      },
      {
        path: 'budget-detail',
        name: 'BudgetDetailContent',
        component: () => import('@/views/Form/detail/budget.vue'),
        meta: {
          title: t('formPage.budgetDetail'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true,
          noCache: true
        }
      },
      {
        path: 'budget-payment-plan',
        name: 'BudgetPaymentPlanContent',
        component: () => import('@/views/Form/budgetPaymentPlan.vue'),
        meta: {
          title: t('formPage.budgetPaymentPlan'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true,
          noCache: true
        }
      },
      {
        path: 'budget-payment-plan-detail',
        name: 'BudgetPaymentPlanDetailContent',
        component: () => import('@/views/Form/detail/budgetPaymentPlan.vue'),
        meta: {
          title: t('formPage.budgetPaymentPlanDetail'),
          icon: 'mdi:file-document-box-search-outline',
          isFull: true,
          noCache: true
        }
      }
    ]
  },
  {
    path: '/log',
    component: Layout,
    name: 'Log',
    redirect: '/log/index',
    meta: {},
    children: [
      {
        path: 'index',
        name: 'LogContent',
        component: () => import('@/views/Log/Index.vue'),
        meta: {
          title: t('router.log'),
          icon: 'mdi:file-document-box-search-outline'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
